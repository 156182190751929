<template>
  <ul class="menu-nav">
    <router-link
      to="/list-rooms"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Săli </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.isSuperAdmin"
      to="/admin/add-room"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Adaugă sală </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.isSuperAdmin"
      to="/admin/all-users"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Utilizatori </span>
        </a>
      </li>
    </router-link>

    <router-link
        v-if="this.isSuperAdmin"
        to="/admin/add-user"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Adaugă utilizator </span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/all-bookings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Rezervari </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["isSuperAdmin"])
  }
};
</script>
